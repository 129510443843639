import React from "react";
import { Link } from "gatsby";

const NavLink = ({ to, text }) => {
  return (
    <Link className="link" activeClassName="active-link" to={to}>
      {text}
    </Link>
  );
};

export default NavLink;
