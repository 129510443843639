import React from "react";

import NavLink from "./nav-link/nav-link";

const NavLinks = () => {
  const navData = [
    { to: "/", text: "Home" },
    { to: "/media", text: "Media" },
    { to: "/about", text: "About" },
    { to: "/contact", text: "Contact" },
    { to: "/submit", text: "Submit" },
  ];

  return navData.map((link, i) => (
    <NavLink to={link.to} text={link.text} key={i} />
  ));
};

export default NavLinks;
