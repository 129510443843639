import React from "react";
import { Link } from "gatsby";

import Logo from "../logo/logo";
import NavDesktop from "../nav/nav-desktop/nav-desktop";
import NavMobile from "../nav/nav-mobile/nav-mobile";

const Header = ({
  offset,
  previousOffset,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
}) => {
  const shadowLimit = 90;
  const scrollLimit = 681;

  const headerClasses = ["header", "padded"];
  if (offset > shadowLimit) {
    headerClasses.push("scrolled");
  }

  if (offset > previousOffset && offset > scrollLimit && !isMobileMenuOpen) {
    headerClasses.push("hide");
  } else {
    headerClasses.push("show");
  }

  return (
    <header className={headerClasses.join(" ")}>
      <div className="logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <NavMobile setIsMobileMenuOpen={setIsMobileMenuOpen} />
      <NavDesktop />
    </header>
  );
};

export default Header;
