import React from "react";
import { Link } from "gatsby";

import Logo from "../logo/logo";

const Footer = () => {
  return (
    <footer>
      <div className="social">
        <a
          className="link-medium"
          href="https://www.instagram.com/design_burger/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        <a
          className="link-medium"
          href="https://www.linkedin.com/company/design-burger/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
        <a
          className="link-medium"
          href="https://www.facebook.com/designburgerfb"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
      </div>
      <div className="logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="nav">
        <Link className="link-medium" to="/about">
          About
        </Link>
        <Link className="link-medium" to="/contact">
          Contact
        </Link>
        <Link className="link-medium" to="/submit">
          Submit
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
