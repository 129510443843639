import React from "react";

import NavLinks from "../nav-links/nav-links";

const NavDesktop = () => {
  return (
    <nav className="nav nav-desktop">
      <NavLinks />
    </nav>
  );
};

export default NavDesktop;
