import React from "react";

const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.715 27.893">
      <g
        id="Group_64"
        data-name="Group 64"
        transform="translate(-475.893 -248.287)"
      >
        <g
          id="Group_62"
          data-name="Group 62"
          transform="translate(475.893 248.287)"
        >
          <path
            id="Path_91"
            data-name="Path 91"
            d="M491.233,248.935v22H487.6v-1.818a5.408,5.408,0,0,1-4.664,2.17,6.565,6.565,0,0,1-5.06-2.156,8.466,8.466,0,0,1,0-10.881,6.564,6.564,0,0,1,5.06-2.156,5.535,5.535,0,0,1,4.341,1.73v-8.887Zm-4.986,17.73a3.876,3.876,0,0,0,1.085-2.977,3.935,3.935,0,0,0-1.085-2.977,3.755,3.755,0,0,0-5.25.059,3.936,3.936,0,0,0-1.085,2.889,4,4,0,0,0,1.07,2.918,3.59,3.59,0,0,0,2.684,1.1A3.657,3.657,0,0,0,486.247,266.665Z"
            transform="translate(-475.893 -248.671)"
          />
          <path
            id="Path_92"
            data-name="Path 92"
            d="M535.348,275.421H525.141a3.7,3.7,0,0,0,1.452,2.127,4.524,4.524,0,0,0,2.655.747,6.529,6.529,0,0,0,4.048-1.466l1.642,2.7a8.788,8.788,0,0,1-5.807,2.17,7.853,7.853,0,0,1-5.69-2.185,7.227,7.227,0,0,1-2.259-5.411,7.489,7.489,0,0,1,7.6-7.6,6.572,6.572,0,0,1,6.834,6.746A9.385,9.385,0,0,1,535.348,275.421Zm-8.917-4.927a4.049,4.049,0,0,0-1.29,2.141h6.629a2.781,2.781,0,0,0-.939-2.141,3.234,3.234,0,0,0-2.2-.792A3.306,3.306,0,0,0,526.431,270.494Z"
            transform="translate(-502.734 -259.084)"
          />
          <path
            id="Path_93"
            data-name="Path 93"
            d="M572.392,268.633l-1.995,2.435a4.649,4.649,0,0,0-3.256-1.378q-1.554,0-1.554,1.026a.86.86,0,0,0,.337.689,4.479,4.479,0,0,0,1.188.6l1.408.587a9.332,9.332,0,0,1,3.094,1.833,3.509,3.509,0,0,1,.983,2.6,4.057,4.057,0,0,1-1.6,3.4,6.461,6.461,0,0,1-4.062,1.232,7.117,7.117,0,0,1-6.013-2.815l1.994-2.405A5.647,5.647,0,0,0,567,278.4a2.584,2.584,0,0,0,1.32-.308.978.978,0,0,0,.528-.894.839.839,0,0,0-.337-.719,6.277,6.277,0,0,0-1.159-.6l-1.554-.645a8.96,8.96,0,0,1-2.977-1.818,3.56,3.56,0,0,1-.983-2.611,3.851,3.851,0,0,1,1.481-3.212,6.081,6.081,0,0,1,3.857-1.158A6.66,6.66,0,0,1,572.392,268.633Z"
            transform="translate(-526.288 -259.042)"
          />
          <path
            id="Path_94"
            data-name="Path 94"
            d="M599.881,248.991a2.318,2.318,0,0,1,.748,1.76,2.283,2.283,0,0,1-.748,1.73,2.536,2.536,0,0,1-1.8.7,2.486,2.486,0,0,1-1.789-.7,2.305,2.305,0,0,1-.733-1.73,2.34,2.34,0,0,1,.733-1.76,2.489,2.489,0,0,1,1.789-.7A2.539,2.539,0,0,1,599.881,248.991Zm.191,21.558h-3.96V256.03h3.96Z"
            transform="translate(-546.813 -248.287)"
          />
          <path
            id="Path_95"
            data-name="Path 95"
            d="M629.758,266.828v12.905a6.889,6.889,0,0,1-2.053,5.4,7.848,7.848,0,0,1-5.456,1.848,10.352,10.352,0,0,1-7.128-2.7l2.083-2.757a7.8,7.8,0,0,0,4.722,1.877,4.307,4.307,0,0,0,2.816-.865,3.126,3.126,0,0,0,1.056-2.567v-.792a5.7,5.7,0,0,1-4.429,1.73,6.679,6.679,0,0,1-4.986-2.009,7.878,7.878,0,0,1,0-10.412,6.729,6.729,0,0,1,4.986-1.98,5.482,5.482,0,0,1,4.751,2.141v-1.819Zm-4.986,9.708a3.8,3.8,0,0,0,1.085-2.9,3.547,3.547,0,0,0-1.07-2.816,3.8,3.8,0,0,0-2.6-.938,3.753,3.753,0,0,0-2.684,1.012,3.631,3.631,0,0,0-1.071,2.771,3.8,3.8,0,0,0,1.086,2.831,3.81,3.81,0,0,0,5.25.044Z"
            transform="translate(-557.993 -259.084)"
          />
          <path
            id="Path_96"
            data-name="Path 96"
            d="M673.6,268.1a6.025,6.025,0,0,1,1.466,4.238v9h-3.93V272.87a2.817,2.817,0,0,0-.748-2.039,2.585,2.585,0,0,0-1.95-.777,2.682,2.682,0,0,0-1.98.777,2.756,2.756,0,0,0-.778,2.039v8.476h-3.96V266.828h3.637v1.32a5.755,5.755,0,0,1,4.253-1.642A5.147,5.147,0,0,1,673.6,268.1Z"
            transform="translate(-586.028 -259.084)"
          />
          <path
            id="Path_97"
            data-name="Path 97"
            d="M737.362,258.248a8.465,8.465,0,0,1,0,10.881,6.564,6.564,0,0,1-5.06,2.156,5.406,5.406,0,0,1-4.663-2.17v1.818H724v-22h3.96v8.887a5.534,5.534,0,0,1,4.34-1.73A6.563,6.563,0,0,1,737.362,258.248Zm-3.109,8.329a4,4,0,0,0,1.07-2.918,3.936,3.936,0,0,0-1.085-2.889,3.773,3.773,0,0,0-5.265-.059,3.964,3.964,0,0,0-1.07,2.977,3.876,3.876,0,0,0,1.085,2.977,3.658,3.658,0,0,0,2.581,1.012A3.59,3.59,0,0,0,734.253,266.577Z"
            transform="translate(-622.94 -248.671)"
          />
          <path
            id="Path_98"
            data-name="Path 98"
            d="M782.306,275.422a6.43,6.43,0,0,1-6.658,6.746,6.619,6.619,0,0,1-4.825-1.848,6.537,6.537,0,0,1-1.892-4.9V267.3h3.959v8.447a2.641,2.641,0,0,0,2.757,2.786,2.637,2.637,0,0,0,1.921-.763,2.709,2.709,0,0,0,.777-2.024V267.3h3.96Z"
            transform="translate(-649.568 -259.554)"
          />
          <path
            id="Path_99"
            data-name="Path 99"
            d="M819.966,267.041v3.49H818.91a4.087,4.087,0,0,0-2.845.953,3.62,3.62,0,0,0-1.055,2.831v7.244h-3.96V267.041h3.637v1.76a4.786,4.786,0,0,1,3.989-1.936A4.291,4.291,0,0,1,819.966,267.041Z"
            transform="translate(-674.53 -259.298)"
          />
          <path
            id="Path_100"
            data-name="Path 100"
            d="M851.733,266.828v12.905a6.889,6.889,0,0,1-2.053,5.4,7.848,7.848,0,0,1-5.456,1.848,10.351,10.351,0,0,1-7.127-2.7l2.083-2.757A7.8,7.8,0,0,0,843.9,283.4a4.3,4.3,0,0,0,2.815-.865,3.125,3.125,0,0,0,1.056-2.567v-.792a5.7,5.7,0,0,1-4.429,1.73,6.679,6.679,0,0,1-4.986-2.009,7.879,7.879,0,0,1,0-10.412,6.729,6.729,0,0,1,4.986-1.98,5.482,5.482,0,0,1,4.752,2.141v-1.819Zm-4.986,9.708a3.8,3.8,0,0,0,1.085-2.9,3.546,3.546,0,0,0-1.07-2.816,3.8,3.8,0,0,0-2.6-.938,3.753,3.753,0,0,0-2.683,1.012,3.63,3.63,0,0,0-1.071,2.771,3.8,3.8,0,0,0,1.086,2.831,3.81,3.81,0,0,0,5.25.044Z"
            transform="translate(-689.551 -259.084)"
          />
          <path
            id="Path_101"
            data-name="Path 101"
            d="M895.847,275.421H885.64a3.7,3.7,0,0,0,1.452,2.127,4.523,4.523,0,0,0,2.654.747,6.53,6.53,0,0,0,4.048-1.466l1.642,2.7a8.788,8.788,0,0,1-5.808,2.17,7.852,7.852,0,0,1-5.69-2.185,7.227,7.227,0,0,1-2.259-5.411,7.489,7.489,0,0,1,7.6-7.6,6.572,6.572,0,0,1,6.834,6.746A9.4,9.4,0,0,1,895.847,275.421Zm-8.916-4.927a4.049,4.049,0,0,0-1.29,2.141h6.628a2.78,2.78,0,0,0-.939-2.141,3.234,3.234,0,0,0-2.2-.792A3.306,3.306,0,0,0,886.93,270.494Z"
            transform="translate(-716.391 -259.084)"
          />
          <path
            id="Path_102"
            data-name="Path 102"
            d="M933.579,267.041v3.49h-1.056a4.084,4.084,0,0,0-2.845.953,3.619,3.619,0,0,0-1.056,2.831v7.244h-3.96V267.041H928.3v1.76a4.788,4.788,0,0,1,3.989-1.936A4.291,4.291,0,0,1,933.579,267.041Z"
            transform="translate(-741.866 -259.298)"
          />
        </g>
        <g
          id="Group_63"
          data-name="Group 63"
          transform="translate(675.771 248.348)"
        >
          <path
            id="Path_103"
            data-name="Path 103"
            d="M970.369,249.5h-1.339v3.4h-1.095v-3.4H966.6v-.987h3.773Zm5.064,3.4h-1.095V250.93l-.949,1.193h-.352l-.939-1.193v1.975h-1.1v-4.468h.361l1.868,2.317,1.867-2.317h.342Z"
            transform="translate(-966.595 -248.437)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
