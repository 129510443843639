import React, { useState, useEffect } from "react";
import usePrevious from "../../hooks/usePrevious";
import useNoScroll from "../../hooks/use-no-scroll";
import Header from "./header";
import Footer from "./footer";

import "../../../styles/_main.scss";

const Layout = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const previousOffset = usePrevious(offset);

  useNoScroll(isMobileMenuOpen);

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.addEventListener("scroll", () => handleScroll());
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    let currentPageYOffset = window.pageYOffset;
    setOffset(currentPageYOffset);
  };

  return (
    <div className="layout">
      <Header
        offset={offset}
        previousOffset={previousOffset}
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
