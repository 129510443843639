import React, { useState } from "react";

import NavLinks from "../nav-links/nav-links";

export default function NavMobile({ setIsMobileMenuOpen }) {
  const [open, setOpen] = useState(false);

  function toggle() {
    setIsMobileMenuOpen(!open);
    setOpen(!open);
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      toggle();
    }
  }

  let burgerStyles = ["burger"];
  let drawerStyles = ["drawer", "drawer-closed"];
  let backdropStyles = ["backdrop", "backdrop-closed"];

  if (open) {
    drawerStyles = ["drawer", "drawer-open"];
    backdropStyles = ["backdrop", "backdrop-open"];
    burgerStyles = ["burger", "fixed"];
  }
  return (
    <div className={"nav-mobile"}>
      <div className={burgerStyles.join(" ")}>
        <input
          type="checkbox"
          checked={open}
          onChange={() => {
            toggle();
          }}
        />
        <span className={"top"}></span>
        <span className={"middle"}></span>
        <span className={"bottom"}></span>
      </div>

      <div className={drawerStyles.join(" ")}>
        <NavLinks />
      </div>

      <div
        role="button"
        aria-label="Close mobile menu"
        className={backdropStyles.join(" ")}
        onClick={toggle}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      ></div>
    </div>
  );
}
