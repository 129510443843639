import { useEffect } from "react";

function useNoScroll(condition) {
  const hasWindow = typeof window !== "undefined";

  useEffect(() => {
    if (document !== "undefined" && hasWindow) {
      if (condition) {
        disableScroll();
      } else {
        enableScroll();
      }
    }
  }, [condition, hasWindow]);
}

function disableScroll() {
  // Get the current page scroll position
  let scrollTop = window.pageYOffset; // || document.documentElement.scrollTop;
  let scrollLeft = window.pageXOffset; // || document.documentElement.scrollLeft;

  // if any scroll is attempted, set this to the previous value
  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop);
  };
}

function enableScroll() {
  window.onscroll = function () {};
}

export default useNoScroll;
